<template>
  <div class="home">
    <img alt="Vue logo" src="" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>
